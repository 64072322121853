import PropTypes from 'prop-types'
import React from 'react'

import * as skillLevelStyles from './skill-level.module.css'

const SkillLevel = ({ img, title, description, className }) => (
  <div className={`${skillLevelStyles.container} ${className} wrapper`}>
    <img src={img} alt={title} className={skillLevelStyles.img} />
    <div className={skillLevelStyles.content}>
      <h4 className={skillLevelStyles.title}>{title}</h4>
      <p className={skillLevelStyles.description}>{description}</p>
    </div>
  </div>
)

SkillLevel.propTypes = {
  img: PropTypes.string, // .isRequired
  title: PropTypes.string,
  description: PropTypes.string,
}

SkillLevel.defaultProps = {
  img: '../images/image.png',
  title: 'Poziom początkujący',
  description: '...',
}

export default SkillLevel
