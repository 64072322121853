import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SkillLevel from '../components/skill-level'
import level1 from '../images/piorka1.png'
import level2 from '../images/piorka2.png'
import level3 from '../images/piorka3.png'
import * as ofertaStyles from '../styles/oferta.module.css'

const OfertaPage = () => (
  <>
    <SEO title="Oferta - MusicSpot" />
    <section className="sub-page-header">
      <div className="wrapper">
        <h1>Oferta</h1>
        <p>
        W Music zapraszamy wszystkich, którzy chcą rozpocząć naukę śpiewu 
        lub gry na instrumencie, bez względu na doświadczenie. Nasz program jest elastyczny 
        i dostosowywany do indywidualnych potrzeb ucznia, z uwzględnieniem jego poziomu 
        zaawansowania, wieku i umiejętności. Oferujemy indywidualne zajęcia, które są 
        skrojone na miarę każdego ucznia.
        </p>
      </div>
    </section>
    <section className="skill-levels">
      <SkillLevel
        img={level1}
        title="Poziom Podstawowy"
        description="Na Poziomie Podstawowym rozpoczynamy naszą muzyczną podróż od samego początku. Tutaj skupiamy się na nauce i wdrażaniu fundamentów, które będą kluczowe w dalszym rozwoju. Nasz program cechuje elastyczność, by zindywidualizować naukę pod umiejętności i zainteresowania każdego ucznia, co stanowi fundament do kontynuacji nauki i rozwoju muzycznego talentu."
        className={ofertaStyles.skillLevel}
      />
      <SkillLevel
        img={level2}
        title="Poziom Średniozaawansowany"
        description="Na Poziomie Średniozaawansowanym kontynuujemy naszą naukę, dostosowując ją do umiejętności i muzycznych zainteresowań każdego ucznia. Na tym etapie zachęcamy uczniów do wyrażania swojej kreatywności i indywidualnego stylu muzycznego. Uczymy, jak tworzyć własną muzykę i improwizować, co pomaga w rozwoju artystycznym. W miarę postępów wprowadzamy także elementy współpracy z innymi muzykami, aby umożliwić praktyczne wykorzystanie zdobytej wiedzy podczas występów na scenie."
        className={ofertaStyles.skillLevel}
      />
      <SkillLevel
        img={level3}
        title="Poziom Zaawansowany"
        description="Na Poziomie Zaawansowanym nasze zajęcia to głębsze wnikanie w muzyczną sztukę. Indywidualnie dostosowujemy aspekty techniczne, biorąc pod uwagę preferowaną stylistykę i potrzeby każdego muzyka. To także idealny etap dla osób z klasycznym wykształceniem muzycznym, które pragną poszerzyć swoje horyzonty w kierunku muzyki jazzowej. Nasza nauka na Poziomie Zaawansowanym koncentruje się na doskonaleniu technicznych aspektów gry na instrumencie lub śpiewu. Oferujemy zaawansowane ćwiczenia i techniki, które pozwalają na osiągnięcie mistrzostwa w grze. Wspieramy uczniów w odkrywaniu i rozwijaniu ich unikalnego stylu muzycznego. Pomagamy w tworzeniu własnych interpretacji utworów, aranżacji i kompozycji, co pozwala na pełne artystyczne wyrażenie."
        className={ofertaStyles.skillLevel}
      />
    </section>
  </>
)

export default OfertaPage
